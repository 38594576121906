<script>
import { withQuery } from '@maxsystems/client'
import { currency, number, phone } from '@maxsystems/ui/vue/filters'
import { getAppraisal } from '@maxsystems/xplat/core/data-source/queries'

import CheckTemplate from './components/CheckTemplate'
import ExtendedDisclosures from './components/ExtendedDisclosures.vue'

export default {
  name: 'CustomerOffer',
  components: {
    CheckTemplate,
    ExtendedDisclosures
  },
  filters: {
    currency,
    phone
  },

  props: {
    print: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
    return {
      loading: true,
      appraisal: null,
      error: null,
      query: withQuery(getAppraisal)
    }
  },

  computed: {
    expiresAt () {
      if (!this.appraisal.offer?.expiresAt) {
        const date = new Date()
        date.setDate(date.getDate() + 5)
        return date
      }

      return new Date(this.appraisal.offer.expiresAt)
    },

    mileage () {
      if (!this.appraisal.vehicle.odometer?.value) return
      return number(this.appraisal.vehicle.odometer.value)
    },

    amount: ({ appraisal }) => appraisal?.offer?.value?.amount,
    originalAmount: ({ appraisal }) => appraisal?.offer?.original?.amount,

    vehicleModel () {
      const { year, make, model, trim } = this.appraisal.vehicle.ymmt
      // Merge data ignoring (not expected) null values
      return [year, make, model, trim].join(' ')
    },

    address () {
      const { street, locality, region, postalCode } = this.appraisal.dealer.address || {}
      return { street, locality, region, postalCode }
    },

    dealerWebsite: ({ appraisal }) => {
      const url = appraisal?.dealer?.url
      if (!url) return null
      if (url.startsWith('http')) return url
      // Prepend protocol since some dealers have it missing due to configuration issue
      return `https://${url}`
    },

    dealerWebsiteHost: ({ dealerWebsite }) => {
      try {
        return new URL(dealerWebsite).host
      } catch {
        return null
      }
    },

    expirationDate () {
      const options = { month: 'long', day: 'numeric', year: 'numeric' }
      return this.expiresAt.toLocaleString('en-US', options)
    },

    hasExpired () {
      return new Date(Date.now()) >= this.expiresAt
    },

    remainingDays () {
      const expiredText = 'This offer has expired. Your'
      if (!this.expiresAt || this.hasExpired) return expiredText

      const diffTime = Math.abs(new Date(Date.now()) - this.expiresAt)
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
      const remainingDays = diffDays > 1 ? `${diffDays} days` : '1 day'
      return `This offer is good for ${remainingDays}. After ${remainingDays}, your`
    },

    profile: vm => vm.appraisal?.customer?.profile
  },

  async created () {
    this.$vuetify.theme.dark = false
    await this.getAppraisal(this.$route.params.id)
    this.loading = false
  },

  methods: {
    async getAppraisal (id) {
      const { data, errors } = await this.query.fetch({ id })
      if (errors) this.error = true
      if (errors || !data?.appraisal) return
      this.appraisal = data.appraisal
    }
  }
}
</script>

<template>
  <VContainer
    class="container--customer-offer pa-16"
    :class="{ print }"
    fluid
  >
    <VOverlay
      v-if="loading"
      absolute
      :value="loading"
    >
      <VProgressCircular
        indeterminate
        size="64"
      />
    </VOverlay>
    <template v-else>
      <template v-if="appraisal">
        <img
          v-if="appraisal.dealer.logo"
          :src="appraisal.dealer.logo.url"
          class="container--customer-offer--dealer-logo"
          :alt="appraisal.dealer.name"
        >
        <section class="offer__header text-center mb-10 pb-10">
          <h1 class="offer__title text-h5 text--secondary mb-2">
            Customer Offer
          </h1>
          <h2
            v-if="amount"
            ref="amount"
            class="offer__amount text-h2 font-weight-bold mb-6"
          >
            {{ amount | currency }}
          </h2>
          <h4 class="offer__model text-h4 mb-3">
            {{ vehicleModel }}
          </h4>
          <div class="text--secondary">
            <span>VIN: {{ appraisal.vehicle.vin }}</span>
            <span
              v-if="mileage"
              ref="mileage"
              class="ml-10"
            >Mileage {{ mileage }}</span>
          </div>
        </section>
        <ExtendedDisclosures
          class="vehicle-conditions"
          :disclosures="appraisal.valuation.disclosures"
        />
        <div
          v-if="originalAmount && amount !== originalAmount"
          ref="adjustedOffer"
          class="offer-values mb-5"
        >
          <VRow no-gutters>
            <VCol cols="2">
              <h4 class="font-weight-regular mb-2">
                Original offer
              </h4>
            </VCol>
            <VCol cols="5">
              {{ originalAmount | currency }}
            </VCol>
          </VRow>
          <VRow no-gutters>
            <VCol cols="2">
              <h4 class="font-weight-regular">
                Adjusted offer
              </h4>
            </VCol>
            <VCol cols="5">
              {{ amount | currency }}
            </VCol>
          </VRow>
        </div>
        <VRow class="dealer-container">
          <VContainer
            v-if="profile"
            ref="profile"
            class="dealer-details grey lighten-5 my-10 pt-0 pb-5 px-10 text-body-2"
          >
            <VRow
              class="pt-6 pb-7 dealer-details__header"
              no-gutters
            >
              <VCol class="text-subtitle-1 font-weight-medium text-uppercase text-center text--secondary">
                {{ profile.name }}
              </VCol>
            </VRow>
            <VRow
              class="mb-1"
              no-gutters
            >
              <VCol cols="5">
                Phone Number
              </VCol>
              <VCol>{{ profile.phone | phone }}</VCol>
            </VRow>
            <VRow
              class="mb-1"
              no-gutters
            >
              <VCol cols="5">
                Email Address
              </VCol>
              <VCol>
                {{ profile.email }}
              </VCol>
            </VRow>
          </VContainer>
          <VContainer
            ref="dealer"
            class="dealer-details grey lighten-5 my-10 pt-0 pb-5 px-10 text-body-2"
          >
            <VRow
              class="pt-6 pb-7 dealer-details__header"
              no-gutters
            >
              <VCol class="text-subtitle-1 font-weight-medium text-uppercase text-center text--secondary">
                {{ appraisal.dealer.name }}
              </VCol>
            </VRow>
            <VRow
              class="mb-1"
              no-gutters
            >
              <VCol cols="5">
                Dealer Address
              </VCol>
              <VCol>{{ address.street }}<br>{{ address.locality }}, {{ address.region }} {{ address.postalCode }}</VCol>
            </VRow>
            <VRow
              class="mb-1"
              no-gutters
            >
              <VCol cols="5">
                Dealer Phone
              </VCol>
              <VCol>
                {{ appraisal.dealer.phone | phone }}
              </VCol>
            </VRow>
            <VRow
              v-if="dealerWebsite"
              class="mb-1"
              no-gutters
            >
              <VCol cols="5">
                Dealer Website
              </VCol>
              <VCol>
                <a
                  ref="noopener"
                  class="black--text"
                  :href="dealerWebsite"
                  target="_blank"
                >{{ dealerWebsiteHost }}</a>
              </VCol>
            </VRow>
          </VContainer>
        </VRow>
        <VContainer class="pa-0 text-body-2 mb-6">
          <VRow no-gutters>
            <VCol
              ref="validity"
              cols="6"
              class="valid-through"
            >
              <div class="mb-4">
                {{ !hasExpired && expirationDate ? `Valid Through ${expirationDate}` : 'Expired' }}
              </div>
              <div class="text--secondary">
                {{ remainingDays }} vehicle will need to be reappraised and the offer may change.
              </div>
            </VCol>
          </VRow>
        </VContainer>
        <hr class="dashed mb-6">
        <CheckTemplate
          :appraisal="appraisal"
          :expiration-date="expiresAt"
          :expired="hasExpired"
          :amount="amount"
          :brand-name="appraisal.dealer.name"
          :vin="appraisal.vehicle.vin"
        />
      </template>
      <div v-if="!appraisal">
        Appraisal not found
      </div>
      <div v-if="error">
        An error occurred
      </div>
    </template>
  </VContainer>
</template>

<style>
html {
  overflow-y: unset;
}
</style>

<style lang="scss">
.container--customer-offer {
  max-width: 1100px;
  -webkit-print-color-adjust: exact;

  &--dealer-logo {
    width: 200px;
  }

  .dealer-details {
    width: 470px;
  }

  .details-info {
    font-size: 14px;
  }

  .dashed {
    border: none;
    border-bottom: 1px dashed rgb(0 0 0 / 38%);
  }
}

@mixin print-styles {
  .container--customer-offer {
    padding: 0 24px !important;
  }

  .offer {
    &__header {
      margin-bottom: 16px !important;
      padding-bottom: 0 !important;
    }

    &__title {
      font-size: 1.4rem !important;
      margin-bottom: 0 !important;
    }

    &__amount {
      font-size: 2.8rem !important;
      margin-bottom: 0 !important;
    }

    &__model {
      font-size: 1.8rem !important;
    }
  }

  .dealer-container {
    flex-wrap: nowrap;
    gap: 10px;

    .dealer-details {
      margin: 24px auto !important;
      padding: 0 20px 10px !important;

      &__header {
        padding: 8px 0 !important;
      }
    }
  }

  .valid-through {
    flex: 0 0 60%;
    max-width: 100% !important;
    width: 100% !important;

    & > div {
      margin-bottom: 0 !important;
    }
  }

  // CheckTemplate Overwrites
  .check-template {
    margin-top: 30px;
    page-break-before: always;
  }

  .amount-words {
    padding-right: 10px;
  }

  .text-body-3 {
    font-size: 10px;
  }

  .check-header {
    margin-bottom: 32px !important;
  }

  .check-bottom {
    margin-bottom: 16px !important;
  }

  .signature-info {
    font-size: 12px !important;
  }
}

@media print {
  .v-application {
    @include print-styles;
  }
}

@media print and (min-width: 600px) {
  .v-application {
    .container--customer-offer {
      &--dealer-logo {
        left: 20px;
        position: absolute;
        top: 0;
      }
    }

    .offer-values,
    .vehicle-conditions {
      margin: 0 !important;
    }
  }
}

/*
 * Container to emulate the US Letter size (96 PPI)
 * in a portrait orientation on Storybook
 * to prevent regressions
 */
$us-letter-width: 816px;
$us-letter-height: 1054px;
$start-new-page: calc(#{$us-letter-height} + 50px);

@mixin us-letter-page {
  box-shadow:
    rgb(0 0 0 / 25%) 0 54px 55px,
    rgb(0 0 0 / 12%) 0 -12px 30px,
    rgb(0 0 0 / 12%) 0 4px 6px,
    rgb(0 0 0 / 17%) 0 12px 13px,
    rgb(0 0 0 / 9%) 0 -3px 5px;
  height: $us-letter-height;
  width: $us-letter-width;
}

.print {
  @include us-letter-page;

  margin-bottom: $start-new-page;
  position: relative;

  &::before {
    content: "";
    display: block;
    left: 0;
    position: absolute;
    top: $start-new-page;

    @include us-letter-page;
  }

  @include print-styles;

  .check-template {
    left: 7%;
    position: absolute;
    top: calc(#{$start-new-page} + 40px);

    /*
     * Value to ensure the same line break
     * from the US Letter portrait print view
     */
    width: 662px !important;
  }
}

</style>
