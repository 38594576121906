<script>
import { mdiLock } from '@mdi/js'
import writtenNumber from 'written-number'

import { addIcons } from '@maxsystems/ui/vue'
import { number, date } from '@maxsystems/ui/vue/filters'

addIcons({ lock: mdiLock })

export default {
  name: 'CheckTemplate',
  props: {
    brandName: {
      type: String,
      default: null
    },
    expirationDate: {
      type: Date,
      default: null
    },
    amount: {
      type: Number,
      default: null
    },
    expired: {
      type: Boolean,
      default: true
    },
    vin: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      number
    }
  },

  computed: {
    amountInWords: ({ amount }) => {
      if (!amount) return false
      return writtenNumber(amount, {
        lang: 'en',
        unit: '$',
        currency: 'USD'
      })
    },

    numericExpireDate () {
      if (!this.expirationDate) return false

      return date(this.expirationDate, 'M-D-YYYY')
    },

    todayDate () {
      const options = { month: 'long', day: 'numeric', year: 'numeric' }
      return new Date(Date.now()).toLocaleString('en-US', options)
    }
  }
}
</script>

<template>
  <div class="check-template px-4 py-3">
    <section class="check-header d-flex mb-10 justify-space-between">
      <div class="brand text-h5 font-weight-medium">
        {{ brandName }}
      </div>
      <div>
        <p class="mb-0 text-h5 font-weight-medium text-right">
          NOT A DRAFT
        </p>
        <p
          ref="expirationInfo"
          class="mb-0 text-subtitle-2 text-right"
        >
          {{ !expired && numericExpireDate ? `Expires: ${numericExpireDate} and/or 150 miles` : 'Expired' }}
        </p>
      </div>
    </section>
    <section>
      <VRow class="ma-0 justify-end d-flex">
        <div class="font-weight-medium pt-2 mr-2">
          DATE
        </div>
        <div class="date-field bottom-border mb-6">
          <span ref="todayDate">{{ todayDate }}</span>
        </div>
      </VRow>
      <div class="check-template--check-value">
        <VRow class="ma-0 d-flex align-end">
          <div class="pa-0 col-8">
            <div class="full-width bottom-border right-border">
              <div class="font-weight-bold pb-2 order">
                PAY TO THE ORDER OF
              </div>
            </div>
          </div>
          <div class="pa-0 col-4">
            <VRow class="d-flex justify-end ma-0">
              <div class="font-weight-medium text-h4 mr-2 text-right col-2 pa-0">
                $
              </div>
              <div
                ref="amountValue"
                class="check-template--check-value--amount-number border text-h5 date-field px-2 py-0 col-8 font-weight-medium"
              >
                {{ amount ? number(amount) : '-' }}
              </div>
            </VRow>
          </div>
        </VRow>
        <VRow class="d-flex ma-0 py-2">
          <div class="pa-0 col-8 second bottom-border">
            <div
              ref="amountInWords"
              class="amount-words text-h5 align-end d-flex font-italic fill-height pl-4"
            >
              {{ amountInWords ? `${amountInWords} and 00/100` : '-' }}
            </div>
          </div>
          <div
            class="pa-0 col-4 d-flex align-center fill-height"
          >
            <div class="mt-3 mr-3 font-weight-medium">
              DOLLARS
            </div>
            <VIcon
              class="mr-1 mb-1"
            >
              $lock
            </VIcon>
            <div class="security font-weight-bold">
              Security Features<br>Included
            </div>
          </div>
        </VRow>
      </div>
      <VRow class="check-bottom d-flex ma-0 my-10 align-end text-center">
        <div
          class="col-6 pr-6 pa-0 d-flex align-center"
        >
          <VRow class="font-weight-bold col-2 pa-0 ma-0 mr-2">
            MEMO
          </VRow>
          <VRow class="full-width ma-0 text-body-2">
            <VRow class="d-flex ma-0">
              <div
                class="bottom-border full-width ma-0 col-14 text-body-2"
              >
                <span class="signature-info text-body-1">Only the GM or GSM can sign this Offer</span>
              </div>
            </VRow>
            <VRow class="bottom-text justify-center ma-0 text-body-1">
              VIN: <span class="font-weight-bold pl-1">{{ vin }}</span>
            </VRow>
          </VRow>
        </div>

        <div class="col-6 px-6 text-body-2">
          <VRow class="d-flex bottom-border full-width pa-1 ma-0" />
          <VRow class="bottom-text d-flex justify-center mt-0">
            General Manager
          </VRow>
        </div>
      </VRow>
    </section>
  </div>
</template>

<style lang="scss">
.check-template {
  /* stylelint-disable-next-line max-line-length */
  box-shadow: 0 0 0 1px #000, 0 0 0 2px #FFF, 0 0 0 4px #000, 0 0 0 5px #FFF, 0 0 0 6px #000, 0 0 0 14px #FFF, 0 0 0 15px #CBCBCB;

  // make the 15px border fit within limits of the parent container
  margin-left: 15px;
  width: calc(100% - 30px);

  .border {
    border: solid 2px black;
  }

  .bottom-border {
    border-bottom: solid 2px black;
  }

  .right-border {
    border-right: solid 2px black;
  }

  .date-field {
    width: 200px;
  }

  &--check-value {
    &--amount-number {
      line-height: unset !important;
    }
  }

  .full-width {
    width: 100%;
  }

  .order {
    font-size: 12px;
    max-width: 100px;
  }

  .security {
    font-size: 8px;
    width: 100px;
  }

  .bottom-text {
    height: 24px;
  }
}
</style>
